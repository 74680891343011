import { render, staticRenderFns } from "./PartsColor.vue?vue&type=template&id=461c98f6"
import script from "./PartsColor.vue?vue&type=script&lang=js"
export * from "./PartsColor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IsCloseout: require('/codebuild/output/src1936382078/src/client/components/productView/IsCloseout.vue').default,InventoryStatus: require('/codebuild/output/src1936382078/src/client/components/productView/InventoryStatus.vue').default})
